import type { RouterLinkOptions } from './useRouterLink'
import PersonIcon from '~/assets/icons/people/person.svg'
import PersonIdeaIcon from '~/assets/icons/people/personIdea.svg'
import PersonTalkIcon from '~/assets/icons/people/personTalk.svg'
import PersonFlowIcon from '~/assets/icons/people/personFlow.svg'
import GroupIcon from '~/assets/icons/people/group.svg'
import TeamworkIcon from '~/assets/icons/people/teamwork.svg'
import CityIcon from '~/assets/icons/places/city.svg'
import HouseIcon from '~/assets/icons/places/house.svg'
import HeartLineIcon from '~/assets/icons/topics/heartLine.svg'
import CovidIcon from '~/assets/icons/topics/covid.svg'
import AidIcon from '~/assets/icons/topics/aid.svg'
import MaskIcon from '~/assets/icons/topics/mask.svg'
import HospitalIcon from '~/assets/icons/places/hospital.svg'
import SettingsMonitorIcon from '~/assets/icons/topics/settingMonitor.svg'
import FolderMonitorIcon from '~/assets/icons/topics/folderMonitor.svg'
import ReportIcon from '~/assets/icons/topics/report.svg'
import WarningIcon from '~/assets/icons/communication/warning.svg'
import ExplosionIcon from '~/assets/icons/topics/explosion.svg'
import DialogeIcon from '~/assets/icons/communication/dialoge.svg'
import DocumentEditIcon from '~/assets/icons/topics/documentEdit.svg'
import MapIcon from '~/assets/icons/places/map.svg'
import PhoneIcon from '~/assets/icons/communication/phone.svg'
import InfoIcon from '~/assets/icons/communication/info.svg'
import QuietIcon from '~/assets/icons/topics/quiet.svg'
import WheelchairIcon from '~/assets/icons/accessibility/wheelchair.svg'
import LogoutIcon from '~/assets/icons/ui/Logout.svg'
import KeyholeIcon from '~/assets/icons/ui/Keyhole.svg'

export type MenuItemConfig = {
  label: string | VNode
  icon?: string | object
  isRouterLink: boolean
  doRender: boolean
  putDividerAbove?: boolean
  putDividerBelow?: boolean
  children?: Record<string, MenuItemConfig>
  routerOptions?: RouterLinkOptions
}

type MenuItem = Record<string, MenuItemConfig>

export default () => {
  const { $i18n } = useNuxtApp()
  const { public: { footer } } = useRuntimeConfig()
  const { formattedFullname, isCityAccount, canAccessPage } = useAuthorization()

  // Dynamic Account Menu Items
  const accountItems = computed((): MenuItem => ({
    '/': {
      label: `${formattedFullname.value}`,
      icon: PersonIcon,
      isRouterLink: true,
      doRender: true,
    },
  }))

  // Static Employee Menu Items
  const cityItems = computed((): MenuItem => ({
    '/admin': {
      label: $i18n.t('navigation.admin'),
      icon: SettingsMonitorIcon,
      isRouterLink: false,
      doRender: true,
      putDividerAbove: true,
      children: {
        '/disease': {
          label: $i18n.t('navigation.admin.diseases'),
          icon: CovidIcon,
          isRouterLink: true,
          doRender: true,
        },
        '/vaccine': {
          label: $i18n.t('navigation.admin.vaccines'),
          icon: TeamworkIcon,
          isRouterLink: true,
          doRender: true,
        },
        '/predisposition': {
          label: $i18n.t('navigation.admin.predispositions'),
          icon: HeartLineIcon,
          isRouterLink: true,
          doRender: true,
        },
        '/symptoms': {
          label: $i18n.t('navigation.admin.symptoms'),
          icon: MaskIcon,
          isRouterLink: true,
          doRender: true,
        },
        '/teams': {
          label: $i18n.t('navigation.admin.teams'),
          icon: GroupIcon,
          isRouterLink: true,
          doRender: true,
        },
        '/institution-types': {
          label: $i18n.t('navigation.admin.institution-types'),
          icon: HospitalIcon,
          isRouterLink: true,
          doRender: true,
        },
        '/templates/messages': {
          label: $i18n.t('navigation.templates.messages'),
          icon: DialogeIcon,
          isRouterLink: true,
          doRender: true,
        },
        '/templates/documents': {
          label: $i18n.t('navigation.templates.documents'),
          icon: DocumentEditIcon,
          isRouterLink: true,
          doRender: true,
        },
        'import-logs': {
          label: $i18n.t('navigation.admin.import-logs'),
          icon: FolderMonitorIcon,
          isRouterLink: true,
          doRender: true,
        },
        '/bearerTokens': {
          label: $i18n.t('navigation.admin.bearerTokens'),
          icon: KeyholeIcon,
          isRouterLink: true,
          doRender: true,
        },
      },
    },
    '/reports': {
      label: $i18n.t('navigation.reports'),
      icon: ReportIcon,
      isRouterLink: false,
      doRender: true,
      putDividerAbove: !canAccessPage('/admin'),
      children: {
        '/diseases': {
          label: $i18n.t('navigation.reports.diseases'),
          icon: CovidIcon,
          isRouterLink: true,
          doRender: true,
        },
        '/institutions': {
          label: $i18n.t('navigation.reports.institutions'),
          icon: HospitalIcon,
          isRouterLink: true,
          doRender: true,
        },
        '/charts': {
          label: $i18n.t('navigation.reports.charts'),
          icon: CovidIcon,
          isRouterLink: true,
          doRender: true,
        },
        '/casemap': {
          label: $i18n.t('navigation.reports.caseMap'),
          icon: MapIcon,
          isRouterLink: true,
          doRender: true,
        },
      },
    },
    '/users/city': {
      label: $i18n.t('navigation.users.employees'),
      icon: CityIcon,
      isRouterLink: true,
      doRender: true,
      putDividerAbove: true,
    },
    '/users/citizens': {
      label: $i18n.t('navigation.users.citizens'),
      icon: HouseIcon,
      isRouterLink: false,
      doRender: true,
      putDividerAbove: !canAccessPage('/users/city'),
      children: {
        '/verified': {
          label: $i18n.t('navigation.users.citizens.verified'),
          icon: PersonTalkIcon,
          isRouterLink: true,
          doRender: true,
        },
        '/new': {
          label: $i18n.t('navigation.users.citizens.new'),
          icon: PersonIdeaIcon,
          isRouterLink: true,
          doRender: true,
        },
        '/needs-check': {
          label: $i18n.t('navigation.users.citizens.needs-check'),
          icon: PersonFlowIcon,
          isRouterLink: true,
          doRender: true,
        },
        '/invalid': {
          label: $i18n.t('navigation.users.citizens.invalid'),
          icon: PersonFlowIcon,
          isRouterLink: true,
          doRender: true,
        },
      },
    },
    '/institutions': {
      label: $i18n.t('navigation.admin.institutions'),
      icon: HospitalIcon,
      isRouterLink: true,
      doRender: true,
      putDividerAbove: !canAccessPage('/users/city') && !canAccessPage('/users/citizens'),
    },
    '/cases': {
      label: $i18n.t('navigation.cases'),
      icon: AidIcon,
      isRouterLink: false,
      doRender: true,
      putDividerAbove: true,
      children: {
        '/': {
          label: $i18n.t('navigation.cases.all'),
          icon: AidIcon,
          isRouterLink: true,
          doRender: true,
        },
        '/filter/highrisk': {
          label: $i18n.t('navigation.cases.filter.highRisk'),
          icon: WarningIcon,
          isRouterLink: true,
          doRender: true,
        },
        '/planned-notifications': {
          label: $i18n.t('navigation.cases.plannedNotifications'),
          icon: PhoneIcon,
          isRouterLink: true,
          doRender: true,
        },
      },
    },
    '/outbreaks': {
      label: $i18n.t('navigation.outbreaks'),
      icon: ExplosionIcon,
      isRouterLink: true,
      doRender: true,
      putDividerAbove: !canAccessPage('/cases'),
    },
  }))
  const selfServiceItems = computed((): MenuItem => ({
    '/self-service/cases': {
      label: $i18n.t('navigation.cases'),
      icon: AidIcon,
      isRouterLink: true,
      doRender: true,
      putDividerAbove: true,
    },
  }))

  const footerMenuItemsConfig = computed((): MenuItem => ({
    '/auth/logout': {
      label: $i18n.t('button.logout'),
      icon: LogoutIcon,
      putDividerAbove: true,
      putDividerBelow: true,
      isRouterLink: true,
      doRender: true,
    },
    '/imprint': {
      label: $i18n.t('navigation.footer.imprint'),
      icon: InfoIcon,
      isRouterLink: true,
      doRender: true,
      routerOptions: {
        external: true,
        href: (footer as { imprintLink: string }).imprintLink,
      },
    },
    '/privacy': {
      label: $i18n.t('navigation.footer.privacy'),
      icon: QuietIcon,
      isRouterLink: true,
      doRender: true,
      routerOptions: {
        external: true,
        href: '/privacy-notice.pdf',
      },
    },
    '/accessibility': {
      label: $i18n.t('navigation.footer.accessibility'),
      icon: WheelchairIcon,
      isRouterLink: true,
      doRender: true,
      routerOptions: {
        external: true,
        href: '/accessibility-declaration.pdf',
      },
    },
  }))

  const menuItemsConfig = computed((): MenuItem => ({
    ...accountItems.value,
    ...(isCityAccount.value ? cityItems.value : selfServiceItems.value),
  }))

  return { menuItemsConfig, footerMenuItemsConfig }
}
